import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { PlayButtonProps } from './Video';
import { transparentize } from 'color2k';

interface StyledVideoContainerProps {
    playing?: boolean;
    cover?: boolean;
    clickable?: boolean;
}

export const StyledVideoAspect = styled.div({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '100%',
    width: '100%',
    iframe: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
});

export const StyledVideoContainer = styled.div<StyledVideoContainerProps>(
    {
        position: 'relative',
        width: 'calc(100% + 4px)', // Related to comment on margin
        height: 'calc(100% + 4px)', // Related to comment on margin
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        transform: 'translate(-2px, -2px)',
        clipPath: 'inset(2px 2px)',
    },
    ifProp(
        {
            playing: true,
            clickable: true,
        },
        {
            cursor: 'pointer',
        }
    )
);

export const StyledPlayerButton = styled.div<PlayButtonProps>(
    ({ theme }) => ({
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 100,
        width: 100,
        top: 'calc(50% - 50px)',
        left: 'calc(50% - 50px)',
        minWidth: 100,
        borderRadius: '50%',
        color: theme.colors.black100,
        backgroundColor: transparentize(theme.colors.white100, 0.2),
        transition: 'all 0.3s ease',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: transparentize(theme.colors.white100, 0.1),
        },
    }),
    switchProp('playing', {
        true: {
            opacity: 0,
            pointerEvents: 'none',
        },
        false: {
            opacity: 1,
        },
    })
);
