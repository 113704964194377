import { breakpoints } from '$theme';
import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { StyledButton } from '$shared/components';
import { StyledAlignContent } from '../AlignContent';
import { M10HeroProps } from './M10Hero';

type StyledM10Props = Pick<M10HeroProps, 'backgroundColor' | 'mediaLayout'>;
type StyledM10ContentProps = {
    mediaSize: M10HeroProps['mediaLayout'];
    contentAlignment: M10HeroProps['horizontalAlignment'];
};

type StyledGradientBackgroundProps = {
    alignment: M10HeroProps['horizontalAlignment'];
};

export const StyledMediaWrapper = styled.div({
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    iframe: {
        pointerEvents: 'none',
    },
});

export const StyledGradientBackground = styled.div<StyledGradientBackgroundProps>(
    {
        position: 'absolute',
        top: '0',
        left: '0',
        height: '100%',
        width: '100%',
        background: `linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 65%)`,
        pointerEvents: 'none',
    },
    ifProp({ alignment: 'center' }, () => ({
        background: 'rgba(0,0,0,0.3)',
    })),
);

export const StyledM10 = styled.div<StyledM10Props>(({ backgroundColor, mediaLayout }) => ({
    position: 'relative',
    width: '100%',
    display: 'grid',
    maxWidth: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    backgroundColor,
    minHeight: 480,
    [breakpoints.sm]: {
        minHeight: mediaLayout == 'full' ? 600 : 480,
    },
    [breakpoints.md]: {
        minHeight: mediaLayout == 'full' ? 800 : 580,
    },
}));

export const StyledM10Content = styled.div<StyledM10ContentProps>(
    () => ({
        maxHeight: 1200,
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
    }),
    ifProp(
        { contentAlignment: 'center' },
        {
            justifyContent: 'center',
        }
    ),

    switchProp('mediaSize', {
        full: {
            [`${StyledMediaWrapper}`]: {
                top: 0,
                left: 0,
                position: 'absolute',
            },
            [`${StyledAlignContent}`]: {
                width: '100%',
                margin: '0 auto',
                zIndex: 1,
            },
        },

        contained: {
            position: 'relative',
            borderRadius: '45px 15px',
            overflow: 'hidden',
            img: {
                borderRadius: '45px 15px',
            },
            [`${StyledMediaWrapper}`]: {
                top: 0,
                left: 0,
                position: 'absolute',
            },
            [`${StyledAlignContent}`]: {
                margin: '0 auto',
                width: '100%',
                zIndex: 1,
                [breakpoints.sm]: {
                    width: '80%',
                },
            },
        },
    })
);

export const StyledM10TitleAbove = styled.div(({ theme }) => ({
    display: 'grid',
    alignItems: 'end',
    gap: theme.spaces[5],
    width: '100%',
    margin: '0 auto',
    paddingBottom: theme.spaces[6],
    [breakpoints.sm]: {
        gridTemplateColumns: '1fr auto',
    },
}));

export const StyledFloatingContent = styled.div<{ isFirstElement?: boolean }>(
    ifProp({ isFirstElement: true }, ({ theme }) => ({
        paddingTop: theme.spaces[5],
        [breakpoints.sm]: {
            paddingTop: theme.spaces[7],
        },
    }))
);

export const StyledButtonWrapper = styled.div(({ theme }) => ({
    width: '100%',
    '> div:first-of-type': {
        marginRight: theme.spaces[4],
        marginBottom: theme.spaces[4],
    },
    [`${StyledButton}`]: {
        width: 'auto',
    },
}));

export const StyledBreadcrumbWrapper = styled.div({
    paddingTop: 45,
});
