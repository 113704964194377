import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpoints, lineHeights } from '~/theme';
import { ifProp } from 'styled-tools';
import { motion } from 'framer-motion';
import { transparentize } from 'color2k';

const buttonBaseStyle = css({
    width: 80,
    height: 80,
    padding: 10,
    borderRadius: 99999,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    textAlign: 'center',
    border: 'none',
    boxShadow: 'rgb(100 100 111 / 5%) 0px 7px 29px 0px',
    [breakpoints.sm]: {
        width: 100,
        height: 100,
    },
    p: {
        lineHeight: lineHeights.caption,
    },
});

export const StyledCursorFollowButton = styled(motion.button)(
    () => ({
        ...buttonBaseStyle,
    }),
    ({ theme }) => ({
        backgroundColor: theme.colors.white100,
        color: theme.colors.black,
        position: 'absolute',
        display: 'none',
        transition: 'background-color 100ms ease',
        '&:active': {
            backgroundColor: theme.colors.grey40,
        },
        [breakpoints.sm]: {
            cursor: 'none',
            display: 'block',
            opacity: 1,
        },
    })
);

export const StyledCursorFollowButtonContainer = styled.div<{
    disableMovement?: boolean | (() => boolean);
    showAlways?: boolean | (() => boolean);
}>(
    () => ({
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        [breakpoints.sm]: {
            overflow: 'hidden',
        },
        [`:hover ${StyledStaticButton}`]: {
            opacity: 1,
        },
    }),
    ifProp(
        'disableMovement',
        () => ({
            cursor: 'unset',
        }),
        () => ({
            cursor: 'none',
        })
    ),
    ifProp('showAlways', () => ({
        [`${StyledStaticButton}`]: {
            opacity: 1,
        },
    }))
);

export const StyledStaticButton = styled.button<{ showAlways?: boolean }>(
    () => ({
        ...buttonBaseStyle,
    }),
    ({ theme }) => ({
        backgroundColor: theme.colors.white,
        color: theme.colors.black,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity: 1,
        cursor: 'pointer',
        transition: `
            opacity ${theme.animations.timingMedium} ${theme.animations.easeOutCubic},
            background-color ${theme.animations.timingMedium} ${theme.animations.easeOutCubic}
        `,
        pointerEvents: 'auto',

        ':hover': {
            backgroundColor: transparentize(theme.colors.white, 0.1),
        },
    }),
    ifProp(
        'showAlways',
        () => ({
            [breakpoints.sm]: {
                opacity: 1,
            },
        }),
        () => ({
            [breakpoints.sm]: {
                opacity: 0,
            },
        })
    )
);
