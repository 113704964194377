import NextLink from 'next/link';
import { memo } from 'react';
import { Gutter, MaxWidth, Text } from '~/shared/components';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';
import { usePage } from '~/templates/pages';
import { StyledBreadcrumb, StyledBreadcrumbContainer, StyledBreadCrumbSeparator } from './styled';

/**
 * Displays the current pages breadcrumb.
 * For now it also handles logic on when to show and when not to show.
 */
const N50Breadcrumb = memo(() => {
    const { breadcrumb, type } = usePage();
    const { items } = breadcrumb || {};

    // Add pages that should not include the last item
    const hideActive = ['p30CategoryPage'].includes(type);

    // Add pages that should not include the breadcrumb
    if (['p20FrontPage', 'p70ProductDetailsPageTemplate'].includes(type)) {
        return null;
    }

    if (!items || !items?.length) {
        return null;
    }

    const activeIndex = items?.length - 1;

    return (
        <MaxWidth>
            <Gutter>
                <nav aria-label="breadcrumb">
                    <StyledBreadcrumbContainer>
                        {items?.map((link, index) => {
                            const key = index;
                            const isActive = index === activeIndex;
                            const isHidden = isActive && hideActive;

                            if (isHidden) {
                                return null;
                            }

                            return (
                                <StyledBreadcrumb
                                    key={key}
                                    // location is prefered over page
                                    // @see https://www.aditus.io/aria/aria-current/#aria-current-location
                                    aria-current={isActive ? 'location' : undefined}
                                >
                                    <NextLink
                                        href={link.url}
                                        passHref
                                        prefetch={false}
                                        legacyBehavior
                                    >
                                        <Text
                                            as="a"
                                            variant="captionSm"
                                            title={link.title}
                                            style={{
                                                wordBreak: 'break-word',
                                                whiteSpace: 'break-spaces',
                                            }}
                                        >
                                            {link.text}
                                        </Text>
                                    </NextLink>
                                    {isActive ? null : (
                                        <StyledBreadCrumbSeparator aria-hidden="true" />
                                    )}
                                </StyledBreadcrumb>
                            );
                        })}
                    </StyledBreadcrumbContainer>
                </nav>
            </Gutter>
        </MaxWidth>
    );
});
N50Breadcrumb.displayName = 'N50Breadcrumb';
const N50BreadcrumbWithErrorBoundary = withErrorBoundary(N50Breadcrumb);
N50BreadcrumbWithErrorBoundary.displayName = 'N50BreadcrumbErrorBoundary';
export default N50BreadcrumbWithErrorBoundary;
