import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
export const StyledAnimatedSlideDownContentContainer = styled.div<{ active: boolean }>(
    ({ theme }) => ({
        position: 'relative',

        ['.animated-content']: {
            opacity: 0,
            willChange: 'transform',
            transform: 'translate(0, 2em)',
            transition: `
                transform ${theme.animations.timingLongest} ${theme.animations.easeOutCubic} 0ms,
                opacity ${theme.animations.timingMedium} ${theme.animations.easeOutCubic} 0ms
            `,
            '.no-js &': {
                opacity: 1,
                transform: 'translate(0,0)',
            },
        },
    }),
    ifProp('active', () => ({
        '.animated-content': {
            opacity: 1,
            transform: 'translate(0, 0)',
        },
    }))
);
