import { StyledText } from '$shared/components/Text';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

export const StyledRichText = styled.div<{ removeLastChildMargin?: boolean }>(
    ({ theme }) => ({
        color: theme.colors.black,
        img: {
            maxWidth: '100%',
            height: 'auto',
        },

        [`h1, h2, h3, h4, h5, h6`]: {
            marginTop: theme.spaces[6],
            marginBottom: theme.spaces[2],
            'strong, i, em, u': {
                fontSize: 'inherit',
                letterSpacing: 'inherit',
                lineHeight: 'inherit',
                textTransform: 'inherit',
                fontWeight: 'inherit',
            },
        },

        [`p, ol, ul, pre, table`]: {
            marginBottom: theme.spaces[4],
        },

        p: {
            minHeight: '1em',
        },

        a: {
            color: theme.colors.red,
        },

        li: {
            marginBottom: theme.spaces[1],
            '> p': {
                marginBottom: 0,
            },
        },

        ol: {
            paddingLeft: 25,
            listStyle: 'decimal',
        },

        ul: {
            paddingLeft: 25,
            listStyle: 'disc',
        },

        code: {
            fontFamily: theme.fonts.mono,
        },

        pre: {
            backgroundColor: theme.colors.grey20,
            padding: 15,
            fontFamily: theme.fonts.mono,
        },
        strong: {
            fontWeight: 700,
        },
        [`${StyledText}`]: {
            color: 'inherit',
        },
    }),
    ifProp('removeLastChildMargin', {
        '> *:last-child': {
            marginBottom: 0,
        },
    })
);

export const StyledTable = styled(StyledText.withComponent('table'))(({ theme }) => ({
    minWidth: '100%',
    borderCollapse: 'collapse',

    // Tables
    'td, th': {
        padding: '0.75em',
        textAlign: theme.direction === 'rtl' ? 'right' : 'left' ,
        border: `1px solid ${theme.colors.grey20}`,
        boxSizing: 'border-box',
    },

    th: {
        fontWeight: theme.fontWeights.bold,
        backgroundColor: theme.colors.grey20,
        fontSize: theme.fontSizes.xs,
    },

    thead: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
    },

    'tbody > tr > th:first-child': {
        position: 'sticky',
        left: 0,
    },
}));
