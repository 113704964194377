import React, { ComponentType, FC } from 'react';
import { ErrorBoundary } from './ErrorBoundary';

/**
 * HOC to wrap the export, provide a optional fallback component
 * @example
 * export default withErrorBoundary(MyComponent, <ErrorBoundaryFallback description="This content is currently not available"/>)
 */
type ErrorBoundaryComponentProps<T> = T & JSX.IntrinsicAttributes;

export function withErrorBoundary<T>(
    WrappedComponent: ComponentType<ErrorBoundaryComponentProps<T>>,
    FallBack?: FC<unknown> | JSX.Element,
): FC<T> {
    return ((props: ErrorBoundaryComponentProps<T>) => (
        <ErrorBoundary fallback={FallBack}>
            <WrappedComponent {...props} />
        </ErrorBoundary>
    )) as FC<T>;
}
