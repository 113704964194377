import { CallToAction, Gutter, MaxWidth, Video, Image, Text } from '$shared/components';
import { useTheme } from '@emotion/react';
import React, {
    Fragment,
    memo,
    ReactElement,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { M10HeroModule, ModuleMediaLayout } from '~/lib/data-contract';
import { queries } from '~/theme/breakpoints';
import { useModuleTheme } from '../../hooks/useModuleTheme';
import { AlignContent } from '../AlignContent';
import { Headline } from '../Headline/Headline';
import { ModuleContainer } from '../ModuleContainer';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';
import {
    StyledM10,
    StyledM10Content,
    StyledMediaWrapper,
    StyledGradientBackground,
    StyledButtonWrapper,
    StyledM10TitleAbove,
    StyledBreadcrumbWrapper,
    StyledFloatingContent,
} from './styled';
import { useInView } from 'react-cool-inview';
import { theme } from '~/theme';
import N50Breadcrumb from '~/features/navigation/components/N50Breadcrumb';
import AnimatedHeroImage from './components/AnimatedHeroImage/AnimatedHeroImage';
import AnimatedSlideDownContent from './components/AnimatedSlideDownContent/AnimatedSlideDownContent';
import { BOT_DETECTOR_CONTEXT } from '$shared/utils/bot-detector';
import { useMyKompanContext } from '~/shared/utils/myKompanContext/hooks/useMyKompanContext';
import { usePage } from '~/templates/pages';

export type M10HeroProps = M10HeroModule;

const ImageContainer = memo(
    ({
        children,
        mediaLayout,
    }: {
        children: ReactElement | ReactElement[];
        mediaLayout: ModuleMediaLayout;
    }) => {
        if (mediaLayout == 'full') {
            return <Fragment>{children}</Fragment>;
        }
        return (
            <MaxWidth>
                <Gutter>{children}</Gutter>
            </MaxWidth>
        );
    },
);

export const M10Hero = ({
    id,
    pageElementIndex,
    image,
    video,
    headline,
    subHeadline,
    callToAction,
    secondaryCallToAction,
    mediaLayout = 'full',
    horizontalAlignment = 'left',
    headlinePosition = 'default',
    spacingTop,
    backgroundColor: initialBackgroundColor,
    ...rest
}: M10HeroProps) => {
    const { sizes, colors } = useTheme();
    const [videoVisible, setVideoVisible] = useState(false);
    const { pageElements, type } = usePage();
    const isBot = useContext(BOT_DETECTOR_CONTEXT);
    const { backgroundColor } = useModuleTheme({
        backgroundColor: initialBackgroundColor,
    });
    const { isMyKompan } = useMyKompanContext();

    const { observe: topObserver, inView: topInView } = useInView({
        rootMargin: '-100px 0px 0px 0px',
    });
    const { observe: bottomObserver, inView: bottomInView } = useInView({
        rootMargin: '-100px 0px 0px 0px',
    });

    useEffect(() => {
        if (!videoVisible) {
            setVideoVisible(topInView || bottomInView);
        }
    }, [topInView, bottomInView]);

    const showBreadCrumbsBelow = useMemo(() => {
        return (
            pageElements?.[0]?.id === id &&
            pageElementIndex === 0 &&
            !isMyKompan &&
            type !== 'p80SearchResultsPage'
        );
    }, [pageElements, id, pageElementIndex, isMyKompan]);

    const mediaType = video ? 'video' : 'image';
    const shouldAnimate =
        !isBot && pageElementIndex == 0 && mediaLayout == 'full' && headlinePosition == 'default';
    const imageSizes = {
        full: `${queries.xs} 100vw, ${queries.pageMax} 100vw, ${sizes.pageMaxWidth}px`,
        contained: `${queries.xs} 100vw, ${queries.pageMax} 50vw, ${sizes.pageMaxWidth / 2}px`,
    };

    const HeadlineMemo = useMemo(() => {
        const headlineTag = (
            <Headline
                children={headline}
                variant="display1"
                style={{
                    color: colors.white,
                }}
            />
        );
        if (!headline) return <></>;
        return shouldAnimate ? (
            <AnimatedSlideDownContent delay={750}>{headlineTag}</AnimatedSlideDownContent>
        ) : (
            <Fragment>{headlineTag}</Fragment>
        );
    }, [headline, pageElementIndex, mediaLayout, headlinePosition, shouldAnimate]);

    const SubHeadlineMemo = useMemo(() => {
        const headlineTag = (
            <Text
                children={subHeadline}
                variant={'caption'}
                as="p"
                style={{
                    color: colors.white,
                    textTransform: 'uppercase',
                    opacity: 0.5,
                }}
            />
        );
        if (!headline) return <></>;
        return shouldAnimate ? (
            <AnimatedSlideDownContent delay={450}>{headlineTag}</AnimatedSlideDownContent>
        ) : (
            <Fragment>{headlineTag}</Fragment>
        );
    }, [subHeadline, pageElementIndex, mediaLayout, headlinePosition, shouldAnimate]);

    const CallToActionMemo = useMemo(() => {
        const buttons = (
            <StyledButtonWrapper>
                {callToAction ? <CallToAction callToAction={callToAction} /> : null}
                {secondaryCallToAction ? (
                    <CallToAction callToAction={secondaryCallToAction} />
                ) : null}
            </StyledButtonWrapper>
        );

        return shouldAnimate ? (
            <AnimatedSlideDownContent delay={950}>{buttons}</AnimatedSlideDownContent>
        ) : (
            <Fragment>{buttons}</Fragment>
        );
    }, [callToAction, secondaryCallToAction, headlinePosition, shouldAnimate]);

    const RenderFloatingContent = useMemo(() => {
        return (
            <StyledFloatingContent isFirstElement={pageElementIndex == 0}>
                <AlignContent
                    alignment={horizontalAlignment}
                    position="center"
                    textColor={colors.white}
                >
                    {SubHeadlineMemo}
                    {HeadlineMemo}
                    {CallToActionMemo}
                </AlignContent>
            </StyledFloatingContent>
        );
    }, [
        callToAction,
        secondaryCallToAction,
        headline,
        subHeadline,
        horizontalAlignment,
        pageElementIndex,
    ]);

    const RenderTitleAbove = useMemo(() => {
        return (
            <MaxWidth>
                <Gutter>
                    <StyledM10TitleAbove>
                        <div>
                            {subHeadline ? (
                                <Text
                                    children={subHeadline}
                                    variant="caption"
                                    as="p"
                                    style={{
                                        color: colors.black,
                                        opacity: 0.5,
                                        paddingBottom: theme.spaces[4],
                                    }}
                                />
                            ) : null}
                            <Headline children={headline} variant="display1" />
                        </div>

                        <StyledButtonWrapper>
                            {callToAction ? <CallToAction callToAction={callToAction} /> : null}
                            {secondaryCallToAction ? (
                                <CallToAction callToAction={secondaryCallToAction} />
                            ) : null}
                        </StyledButtonWrapper>
                    </StyledM10TitleAbove>
                </Gutter>
            </MaxWidth>
        );
    }, [callToAction, secondaryCallToAction, headline, subHeadline]);

    const MemoImage = useMemo(() => {
        if (!image) return <></>;
        return (
            <Fragment>
                <Image
                    disableSkeleton={true}
                    src={image.src}
                    alt={image.alt}
                    layout="fill"
                    objectFit="cover"
                    sizes={imageSizes[mediaLayout]}
                    priority={pageElementIndex < 2}
                    width={image.width}
                    height={image.height}
                    imageFocalPoint={image.imageFocalPoint}
                    disableAnimation={shouldAnimate}
                />
                {headlinePosition !== 'aboveMedia' && (
                    <StyledGradientBackground alignment={horizontalAlignment} />
                )}
            </Fragment>
        );
    }, [image, horizontalAlignment, headlinePosition]);

    return (
        <ModuleContainer
            fullWidth
            {...rest}
            spacingTop={pageElementIndex === 0 ? 'none' : spacingTop}
            spacingBottom={showBreadCrumbsBelow ? 'small' : 'default'}
            pageElementIndex={pageElementIndex}
            disableModuleAnimation={shouldAnimate}
        >
            {headlinePosition === 'aboveMedia' && RenderTitleAbove}
            <div ref={topObserver} />
            <ImageContainer mediaLayout={mediaLayout}>
                <StyledM10 backgroundColor={backgroundColor} mediaLayout={mediaLayout}>
                    <StyledM10Content
                        mediaSize={mediaLayout}
                        contentAlignment={horizontalAlignment}
                    >
                        <MaxWidth>
                            <Gutter>
                                {headlinePosition === 'default' &&
                                    headline &&
                                    RenderFloatingContent}
                                {image || video ? (
                                    <StyledMediaWrapper>
                                        {mediaType === 'image' && image ? (
                                            <Fragment>
                                                {pageElementIndex == 0 &&
                                                mediaLayout == 'full' &&
                                                headlinePosition == 'default' ? (
                                                    <AnimatedHeroImage>
                                                        {MemoImage}
                                                    </AnimatedHeroImage>
                                                ) : (
                                                    <Fragment>{MemoImage}</Fragment>
                                                )}
                                            </Fragment>
                                        ) : null}
                                        {mediaType === 'video' && video ? (
                                            <Video
                                                muted={true}
                                                controls={false}
                                                src={video.src}
                                                loop={video.loop}
                                                playing={
                                                    (mediaLayout === 'full' || video.autoPlay) &&
                                                    videoVisible
                                                }
                                                posterSrc={video.image?.src || image?.src}
                                                cover={true}
                                            >
                                                {headlinePosition !== 'aboveMedia' && (
                                                    <StyledGradientBackground
                                                        alignment={horizontalAlignment}
                                                    />
                                                )}
                                            </Video>
                                        ) : null}
                                    </StyledMediaWrapper>
                                ) : null}
                            </Gutter>
                        </MaxWidth>
                    </StyledM10Content>
                </StyledM10>
            </ImageContainer>
            {showBreadCrumbsBelow && (
                <StyledBreadcrumbWrapper>
                    <N50Breadcrumb />
                </StyledBreadcrumbWrapper>
            )}
            <div ref={bottomObserver} />
        </ModuleContainer>
    );
};

export default withErrorBoundary(M10Hero);
