import { memo, useContext, useEffect, useState } from 'react';
import { StyledAnimatedHeroImageContainer } from './styled';
import { BOT_DETECTOR_CONTEXT } from '$shared/utils/bot-detector';

export interface AnimatedHeroImageProps {
    children?: JSX.Element;
    delay?: number;
}

const AnimatedHeroImage = memo(({ children, delay = 0 }: AnimatedHeroImageProps) => {
    const [active, setActive] = useState(false);
    const isBot = useContext(BOT_DETECTOR_CONTEXT);
    useEffect(() => {
        const timeoutStore = setTimeout(() => {
            setActive(true);
        }, delay);

        return () => {
            clearTimeout(timeoutStore);
        };
    }, []);

    return (
        <StyledAnimatedHeroImageContainer active={active || isBot} isBot={isBot}>
            <div className="animated-image-content">{children}</div>
        </StyledAnimatedHeroImageContainer>
    );
});
export default AnimatedHeroImage;
