import styled, { CSSObject } from '@emotion/styled';

export const StyledBreadcrumbContainer = styled.ol({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
});

export const StyledBreadcrumb = styled.li(({ theme }) => ({
    whiteSpace: 'nowrap',
    '&[aria-current]': {
        color: theme.colors.grey60,
        pointerEvents: 'none',
    },
    '&[aria-current] a': {
        color: theme.colors.grey60,
        pointerEvents: 'none',
    },
    a: {
        color: 'inherit',
        textDecoration: 'none',

        '&:hover, &:focus': {
            textDecoration: 'underline',
        },
    },
}));

export const StyledBreadCrumbSeparator = styled.span(
    ({ theme }) =>
        ({
            ...theme.fontVariants.captionSm,
            position: 'relative',
            display: 'inline-flex',
            height: '1em',
            width: 8,
            padding: '0',
            margin: '0 11px',
            ':after': {
                position: 'absolute',
                content: '""',
                width: '100%',
                height: '2px',
                top: '6px',
                left: 0,
                backgroundColor: theme.colors.red,
            },
        } as CSSObject)
);
