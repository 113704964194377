import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
export const StyledAnimatedHeroImageContainer = styled.div<{ active: boolean; isBot?: boolean }>(
    ({ theme }) => ({
        position: 'relative',
        height: '100%',
        ':after': {
            content: '""',
            backgroundColor: 'white',
            height: '100%',
            width: '100%',
            willChange: 'transform',
            position: 'absolute',
            bottom: 0,
            left: 0,
            transformOrigin: '0 100%',
            transition: `transform ${theme.animations.timingLongest} ${theme.animations.easeOutCubic}`,
        },
        ['.animated-image-content']: {
            willChange: 'transform',
            height: '100%',
            transform: 'scale(1.15)',
            transition: `transform ${theme.animations.timingLongest} ${theme.animations.easeOutCubic} 100ms`,
        },
    }),
    ifProp('active', () => ({
        ':after': {
            transform: 'scaleY(0)',
        },
        '.animated-image-content': {
            transform: 'scale(1)',
        },
    })),
    ifProp('isBot', () => ({
        ':after, .animated-image-content': {
            transition: 'none',
        },
    }))
);
