import { memo, useEffect, useState } from 'react';
import { StyledAnimatedSlideDownContentContainer } from './styled';

export interface AnimatedSlideDownContentProps {
    children?: JSX.Element;
    delay?: number;
}

const AnimatedSlideDownContent = memo(({ children, delay = 0 }: AnimatedSlideDownContentProps) => {
    const [active, setActive] = useState(false);
    useEffect(() => {
        const timeoutStore = setTimeout(() => {
            setActive(true);
        }, delay);

        return () => {
            clearTimeout(timeoutStore);
        };
    }, []);

    return (
        <StyledAnimatedSlideDownContentContainer active={active}>
            <div className="animated-content">{children}</div>
        </StyledAnimatedSlideDownContentContainer>
    );
});
export default AnimatedSlideDownContent;
