import { Text } from '$shared/components';
import { useReducedMotion } from 'framer-motion';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMedia, useMouse } from 'react-use';
import {
    StyledCursorFollowButton,
    StyledCursorFollowButtonContainer,
    StyledStaticButton,
} from './styled';
import { useIsClientSide } from '~/shared/hooks/useIsClientSide/useIsClientSide';

type CursorFollowButtonProps = {
    /**
     * Always show button regardless of hover
     */
    showAlways?: boolean;
    /**
     * Disable cursor based movement
     */
    disableMovement?: boolean;
    children?: React.ReactNode | React.ReactNode[];
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const CursorFollowButton = ({
    showAlways = true,
    disableMovement = false,
    children,
    onClick,
}: CursorFollowButtonProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { elX, elY, elW, elH, docX, docY, posX, posY } = useMouse(containerRef);
    const [isInFront, setIsInFront] = useState(false);
    const isMobile = useMedia('(max-width: 768px)', false);
    const [isHover, setIsHover] = useState(false);
    const defaultStateOpacity = showAlways ? 1 : 0;
    const shouldReduceMotion = useReducedMotion();
    const isClientSide = useIsClientSide();

    useEffect(() => {
        const isInViewX = docX > posX && docX <= posX + elW;
        const isInViewY = docY > posY && docY <= posY + elH;
        setIsHover(isInViewX && isInViewY);
    }, [docX, docY, posX, posY, elW, elH]);

    const handleMouseEnter = () => {
        setIsInFront(true);
    };
    const handleMouseLeave = () => {
        setIsInFront(false);
    };

    // Reduce motion by component or user choice
    const useMotion = useMemo(() => {
        if (shouldReduceMotion == true) return false;
        if (disableMovement == true) return false;
        return true;
    }, [disableMovement, shouldReduceMotion]);

    // Zoom out button if cursor hovers text or buttons
    const elementScale = useMemo(() => {
        if (!isHover) return 1;
        if (!isInFront && isHover) return 0;
        return 1;
    }, [isHover, isInFront]);

    const getPos = useMemo(() => {
        const sizeOffset = {
            top: -50,
            left: -50,
        };
        // If mouseTracker has not yet received data, use reference to calculate center
        if (elW == 0 || elH == 0) {
            return {
                x: containerRef?.current ? containerRef?.current?.offsetWidth / 2 : undefined,
                y: containerRef?.current ? containerRef?.current?.offsetHeight / 2 : undefined,
                ...sizeOffset,
            };
        }
        return isHover
            ? {
                  x: elX,
                  y: elY,
                  ...sizeOffset,
              }
            : {
                  x: elW / 2,
                  y: elH / 2,
                  ...sizeOffset,
              };
    }, [isHover, elX, elY, elW, elH, containerRef?.current]);

    const showMobileButton = useMemo(() => {
        return !useMotion || isMobile;
    }, [useMotion, isMobile]);

    return (
        isClientSide && (
            <StyledCursorFollowButtonContainer
                ref={containerRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                disableMovement={!useMotion}
                showAlways={showAlways}
            >
                {containerRef.current && !showMobileButton && (
                    <StyledCursorFollowButton
                        className="cursorfollowbutton"
                        onClick={(e) => onClick && onClick(e)}
                        animate={
                            !disableMovement && {
                                opacity: isHover ? 1 : defaultStateOpacity,
                                scale: elementScale,
                                ...getPos,
                                transition: {
                                    type: 'spring',
                                    mass: 0.15,
                                    stiffness: isHover ? 160 : 50,
                                    duration: 0,
                                    restDelta: 0.5,
                                },
                            }
                        }
                        initial={{
                            ...getPos, // Set inital position to avoid button animating from side on load
                        }}
                    >
                        <Text variant="body">{children}</Text>
                    </StyledCursorFollowButton>
                )}

                {showMobileButton && (
                    <StyledStaticButton onClick={(e) => onClick && onClick(e)}>
                        <Text variant="body">{children}</Text>
                    </StyledStaticButton>
                )}
            </StyledCursorFollowButtonContainer>
        )
    );
};
