import dynamic from 'next/dynamic';
import React, { useRef, useState, useEffect, useMemo, ReactNode } from 'react';
import { fetchVideoMetadata } from './utils';
import { StyledVideoAspect, StyledVideoContainer } from './styled';
import { useTranslation } from '$shared/utils/translation';
import { CursorFollowButton } from '~/templates/blocks/components/M70Media/components/CursorFollowButton';
import { useTracking } from '$shared/components';
const VideoPlayerLazy = dynamic(() => import('react-player/lazy'), { ssr: false });

export interface PlayButtonProps {
    playing?: boolean;
}

export interface VideoProps {
    /** Video source. Supports YouTube, Vimeo and selfhosted */
    src: string;

    /**
     * Required if selfhosted. Overwrites default poster for YouTube and Vimeo.
     * Default posters from external providers are very low resultion.
     * Providing a posterSrc is always prefered.
     */
    posterSrc?: string;

    /**
     * Show or hide controls. Only the play button will be visible until playing.
     * YouTube: Not all controls can be hidden from youtube.
     * Vimeo: Hidden controls must be enabled by video owner
     */
    controls?: boolean;

    /** Start the video muted. Prefered */
    muted?: boolean;

    /** Loop the video */
    loop?: boolean;

    /** Start playing imidiately */
    playing?: boolean;

    /**
     *  Trigger recalcuating dimensions and repositioning the video.
     *  Usefull in cases where the container resizes without an window resize event
     */
    repositionKey?: string;

    /**
     * Aspect ratio.
     * If not set will automatically determine (requires an additional requst)
     */
    aspectRatio?: number;

    /**
     * Set height to 100%
     */
    cover?: boolean;

    /**
     * Set the default volumn, between 0-1
     */
    volumn?: number;

    /**
     * Triggered when started playing
     */
    onPlay?: () => void;

    /**
     * Triggered when stopd playing
     */
    onPause?: () => void;

    /**
     * Triggered when Video ends
     */
    onVideoEnded?: () => void;

    /**
     * Video provider specific options
     * @see https://www.npmjs.com/package/react-player
     */
    embedConfig?: { [key: string]: unknown };

    children?: ReactNode | ReactNode[];
}

const defaultEmbedOptions = {
    youtube: {
        embedOptions: {
            rel: 0,
            modestbranding: 1,
        },
    },
};

export const Video = ({
    controls = true,
    muted = false,
    loop = false,
    playing = false,
    cover = false,
    src,
    posterSrc,
    repositionKey,
    onPlay,
    onPause,
    volumn,
    embedConfig = defaultEmbedOptions,
    aspectRatio: defaultAspectRatio,
    children,
    onVideoEnded,
}: VideoProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const { trackVideoPlay } = useTracking();
    const [aspectRatio, setAspectRatio] = useState(defaultAspectRatio);
    const [isPlaying, setIsPlaying] = useState(playing);
    const [isAlreadyTracked, setIsAlreadyTracked] = useState(false);
    const [initialPlay, setInitialPlay] = useState(playing);
    const [thumbnailSrc, setThumbnailSrc] = useState(posterSrc);
    const { translate } = useTranslation();
    const isClickable = !(controls == false && playing == true);
    const styles = useMemo(
        () => ({
            backgroundImage: thumbnailSrc ? `url(${thumbnailSrc})` : undefined,
            // Fallback to 16/9
            paddingTop: cover ? 0 : aspectRatio ? `${aspectRatio * 100}%` : '56.25%',
        }),
        [thumbnailSrc, aspectRatio, cover],
    );

    const onPlayHandler = () => {
        setInitialPlay(true);
        setIsPlaying(true);
        onPlay && onPlay();
    };

    const onPauseHandler = () => {
        setIsPlaying(false);
        onPause && onPause();
    };

    /**
     * Only reposition if covering. Otherwise fluid
     */
    useEffect(() => {
        if (!aspectRatio || !cover) {
            return;
        }

        /**
         * Determin if stretch horizontal or vertical
         */
        const scaleVideo = () => {
            const aspectElement = ref?.current;

            if (aspectElement) {
                const parentElement = aspectElement.parentElement as HTMLDivElement;
                const { width, height } = parentElement.getBoundingClientRect();

                const parentRatio = height / width;
                const verticalScale = parentRatio > aspectRatio;

                if (verticalScale) {
                    aspectElement.style.height = '100%';
                    aspectElement.style.width = '1000%';
                } else {
                    aspectElement.style.width = '100%';
                    aspectElement.style.height = '1000%';
                }
            }
        };

        scaleVideo();
        window.addEventListener('resize', scaleVideo);
        return () => window.removeEventListener('resize', scaleVideo);
    }, [aspectRatio, repositionKey]);

    useEffect(() => {
        if (!aspectRatio || !thumbnailSrc) {
            fetchVideoMetadata(src).then(
                ({ aspectRatio: newAspectRatio, thumbnailSrc: newThumbnailSrc }) => {
                    aspectRatio || setAspectRatio(newAspectRatio);
                    thumbnailSrc || setThumbnailSrc(newThumbnailSrc);
                },
            );
        }
    }, []);

    useEffect(() => {
        playing ? onPlayHandler() : onPauseHandler();
    }, [playing]);

    useEffect(() => {
        if (!playing && isPlaying && !isAlreadyTracked) {
            trackVideoPlay({
                video_link: src,
            });
            setIsAlreadyTracked(true);
        }
    }, [isPlaying]);

    return (
        <StyledVideoContainer
            style={styles}
            cover={cover}
            playing={isPlaying}
            onClick={onPlayHandler}
            clickable={isClickable}
        >
            {children}
            <StyledVideoAspect ref={ref}>
                {initialPlay ? (
                    <VideoPlayerLazy
                        key={src}
                        url={src}
                        playing={isPlaying}
                        controls={isPlaying && controls}
                        muted={muted}
                        loop={loop}
                        onPause={onPauseHandler}
                        onPlay={onPlayHandler}
                        config={embedConfig}
                        volume={volumn}
                        onEnded={onVideoEnded}
                    />
                ) : null}
            </StyledVideoAspect>

            {!isPlaying && (
                <CursorFollowButton onClick={onPlayHandler} showAlways={true}>
                    {translate('Kompan.Generic.Play')}
                </CursorFollowButton>
            )}
        </StyledVideoContainer>
    );
};
