import { PotentialThemeColorType } from '$theme';
import React from 'react';
import { StyledAlignContent } from './styled';

export interface AlignContentProps {
    /**
     * Horizontal align content
     */
    alignment?: 'left' | 'center' | 'right';

    /**
     * Horizontal align content
     */
    position?: 'top' | 'center' | 'bottom';

    gap?: React.CSSProperties['gap'];

    textColor?: PotentialThemeColorType;

    children: React.ReactNode;
}

export const AlignContent = ({
    alignment = 'left',
    position = 'top',
    gap = 15,
    children,
    ...rest
}: AlignContentProps) => {
    return (
        <StyledAlignContent alignment={alignment} position={position} gap={gap} {...rest}>
            {children}
        </StyledAlignContent>
    );
};
